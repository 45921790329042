import React, { useRef, useCallback } from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Scrollspy from "react-scrollspy";
import { Link as ScrollLink } from "react-scroll";
import AutoAffix from "../../components/affix/AutoAffix";
import Layout from "../../components/layout";
import Seo from "../../components/seo";

import FormContatti from "../../components/form-contatti";

import Square from "../../svg/square-det.svg";
import SquareImgDx from "../../svg/square-img-dx.svg";
import SquareImgSx from "../../svg/square-img-sx.svg";

const hero = [
  {
    title: "Salesforce",
    description: "Il migliore CRM per grandi aziende e PMI",
  },
];
const breadcrumb = [
  { title: "Home", link: "/" },
  { title: "Competence center", link: "/competence-center/" },
  { title: "Salesforce" },
];

const Salesforce = ({ data, location }) => {
  const { intro, offerta } = data;
  const scrollRef = useRef();
  const getScrollContainer = useCallback(() => scrollRef.current, []);

  return (
    <Layout location={location} hero={hero} breadcrumb={breadcrumb}>
      <Seo title={`Salesforce`} description={`Il migliore CRM per grandi aziende e PMI`} />

      <div className="section section--blue-light-2">
        <Square className="square d-none d-md-block" />
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-10 offset-md-1 col-xl-8 offset-xl-2">
              <p>
                Tutte le aziende hanno la necessità di interagire con i propri clienti ed è
                importante che lo facciano in maniera ottimale. Per questo motivo in Ariadne abbiamo
                scelto la piattaforma CRM numero 1 al mondo:{" "}
                <a
                  href="https://www.salesforce.com/it/"
                  target="_blank"
                  title="Salesforce"
                  rel="noopener noreferrer"
                >
                  Salesforce
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="section">
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-4 col-xl-7 offset-xl-5 mb-0 mb-xl-5">
              <div className="section__img-intro">
                <SquareImgDx className="square-top" />
                <SquareImgSx className="square-bottom" />
                <GatsbyImage image={getImage(intro)} alt="Image intro" className="w-100" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8 offset-md-4 col-xl-7 offset-xl-5 mb-0 mb-xl-5">
              <p>
                La tecnologia di Salesforce, infatti, ci consente di rimanere strettamente connessi,
                in maniera del tutto innovativa, con i nostri clienti e partner, aiutandoci a
                rispondere tempestivamente ai bisogni di ognuno.
              </p>
              <p>
                In questo modo siamo sicuri di ottimizzare i processi di vendita, le strategie di
                marketing e di gestire al meglio le transazioni e il servizio clienti.
              </p>
              <p>
                Scegliendo di proporre Salesforce come sistema CRM, Ariadne punta a focalizzare la
                propria offerta su aspetti ben precisi. Vediamoli nel dettaglio:
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-xl-4 d-none d-md-block">
              <AutoAffix
                affixClassName="sidebar sidebar--affix"
                topClassName="sidebar sidebar--affix-top"
                bottomClassName="sidebar sidebar--affix-bottom"
                viewportOffsetTop={60}
                container={getScrollContainer}
              >
                <div>
                  <nav className="anchor-nav">
                    <Scrollspy
                      items={[
                        "activity-area",
                        "analytics",
                        "sales",
                        "community",
                        "commerce",
                        "marketing",
                        "crm-salesforce-gestione-concessionari",
                      ]}
                      offset={-60}
                      className="anchor-nav__list"
                      currentClassName="anchor-nav__item--active"
                    >
                      <li className="anchor-nav__item">
                        <ScrollLink
                          href={`#activity-area`}
                          to={`activity-area`}
                          className="anchor-nav__link"
                          title="ACTIVITY AREA"
                        >
                          ACTIVITY AREA
                        </ScrollLink>
                      </li>
                      <li className="anchor-nav__item">
                        <ScrollLink
                          href={`#analytics`}
                          to={`analytics`}
                          className="anchor-nav__link"
                          title="Analytics"
                        >
                          ANALYTICS
                        </ScrollLink>
                      </li>
                      <li className="anchor-nav__item">
                        <ScrollLink
                          href={`#sales`}
                          to={`sales`}
                          className="anchor-nav__link"
                          title="Sales"
                        >
                          SALES
                        </ScrollLink>
                      </li>
                      <li className="anchor-nav__item">
                        <ScrollLink
                          href={`#community`}
                          to={`community`}
                          className="anchor-nav__link"
                          title="Community"
                        >
                          COMMUNITY
                        </ScrollLink>
                      </li>
                      <li className="anchor-nav__item">
                        <ScrollLink
                          href={`#commerce`}
                          to={`commerce`}
                          className="anchor-nav__link"
                          title="Commerce"
                        >
                          COMMERCE
                        </ScrollLink>
                      </li>
                      <li className="anchor-nav__item">
                        <ScrollLink
                          href={`#marketing`}
                          to={`marketing`}
                          className="anchor-nav__link"
                          title="Marketing"
                        >
                          MARKETING
                        </ScrollLink>
                      </li>
                      <li className="anchor-nav__item">
                        <ScrollLink
                          href={`#crm-salesforce-gestione-concessionari`}
                          to={`crm-salesforce-gestione-concessionari`}
                          className="anchor-nav__link"
                          title="CRM Salesforce per la gestione concessionari"
                        >
                          CRM Salesforce per la gestione concessionari
                        </ScrollLink>
                      </li>
                    </Scrollspy>
                  </nav>
                </div>
              </AutoAffix>
            </div>
            <div className="col-md-8 offset-md-1 col-xl-7 offset-xl-1 mb-5">
              <div className="anchor-text" ref={scrollRef}>
                <div id="activity-area" className="anchor">
                  <h2 className="anchor-text__title">ACTIVITY AREA</h2>
                  <p>
                    Progettazione e raccolta dei requisiti a partire dalle esigenze specifiche di
                    business di ogni cliente.
                  </p>
                </div>
                <div id="analytics" className="anchor">
                  <h2 className="anchor-text__title">ANALYTICS</h2>
                  <p>
                    Configurazione iniziale del prodotto attraverso la creazione di utenze e la
                    profilazione dei dati per misurare le prestazioni dell'applicazione e
                    identificare eventuali colli di bottiglia.
                  </p>
                </div>
                <div id="sales" className="anchor">
                  <h2 className="anchor-text__title">SALES</h2>
                  <p>
                    Personalizzazione e sviluppo dei processi di automazione per agevolare il
                    processo di vendita richiesto dal cliente.
                  </p>
                </div>
                <div id="community" className="anchor">
                  <h2 className="anchor-text__title">COMMUNITY</h2>
                  <p>
                    Creazione e configurazione di communities cloud opportunamente brandizzate per
                    consentire un'interazione real-time tra clienti e fornitori.
                  </p>
                </div>
                <div id="commerce" className="anchor">
                  <h2 className="anchor-text__title">COMMERCE</h2>
                  <p>
                    Integrazione di servizi esterni per gestire in maniera efficace il processo di
                    generazione degli ordini e l'aggiornamento delle giacenze dei prodotti.
                  </p>
                </div>
                <div id="marketing" className="anchor">
                  <h2 className="anchor-text__title">MARKETING</h2>
                  <p>
                    Gestione delle strategie delle campagne marketing attraverso la creazione di
                    percorsi automatizzati, pubblicità efficaci e l'automazione delle comunicazioni
                    di marketing.
                  </p>
                  <GatsbyImage
                    image={getImage(offerta)}
                    alt="Salesforce Offerta"
                    className="anchor-text__img"
                  />
                </div>
                <div id="crm-salesforce-gestione-concessionari" className="anchor">
                  <h2 className="anchor-text__title">
                    CRM Salesforce per la gestione concessionari
                  </h2>
                  <p>
                    A conferma della qualità di questo sistema, anche un noto marchio
                    automobilistico di alta gamma ha scelto di adottare il CRM Salesforce per la
                    gestione dei suoi concessionari su tutto il territorio nazionale e si è affidata
                    al nostro Gruppo per la progettazione tecnica e la personalizzazione del
                    sistema.
                  </p>
                  <p>
                    La soluzione messa in pratica da Ariadne ha così potenziato tre aspetti
                    importanti per il brand:
                  </p>
                  <ul>
                    <li>
                      Permettere agli amministratori la gestione dei concessionari, del workflow
                      relativo agli ordini e delle campagne <strong>marketing</strong>{" "}
                      personalizzate;
                    </li>
                    <li>
                      Mettere a disposizione di ogni brand afferente alla casa automobilistica una{" "}
                      <strong>community cloud</strong> il cui accesso è riservato esclusivamente ai
                      concessionari e al settore Marketing del brand;
                    </li>
                    <li>
                      Provvedere all'integrazione di servizi esterni per consentire l'aggiornamento
                      dei materiali ordinabili e per l'erogazione degli ordini giornalieri per il
                      settore <strong>commerce</strong>.
                    </li>
                  </ul>
                  <p>
                    Questo progetto in particolare dimostra l'adattabilità del prodotto su cui
                    abbiamo puntato attraverso la personalizzazione di ogni servizio. In Ariadne
                    Group, infatti, siamo fortemente convinti dell'importanza di servirsi di uno
                    strumento che si adatta alle esigenze di ogni cliente, oltre a garantire
                    prestazioni e sicurezza.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FormContatti location={location} />
    </Layout>
  );
};

export default Salesforce;

export const query = graphql`
  query {
    intro: file(relativePath: { eq: "pages/salesforce.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 700, layout: CONSTRAINED)
      }
    }
    offerta: file(relativePath: { eq: "pages/salesforce-offerta.png" }) {
      childImageSharp {
        gatsbyImageData(width: 590, layout: CONSTRAINED)
      }
    }
  }
`;
